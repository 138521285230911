import { useListCategory } from "../../@core/hooks/query/category";
import { StoreFormData } from "../../@core/models";
import { useCreateStore } from "../../@core/hooks";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { setStore } from "../../@core/slice";
import { AddStoreFormBody } from "./add-store-form.hoc";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

interface Props extends React.HTMLProps<HTMLDivElement> {
  closeModal: () => void;
}

export const AddStoreForm: React.FC<Props> = ({ closeModal }) => {
  const { data: categories } = useListCategory();
  const { mutate: createStore } = useCreateStore();
  const navigte = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const categoryOptions = categories?.data?.map((category) => ({
    value: category._id,
    label: category.name,
  }));

  const onSubmit = (values: StoreFormData) => {
    const workingHours = values.workingHours.map((date: any) => {
      return {
        day: date.day,
        operationTimeStart: dayjs(date.operationTimeStart),
        operationTimeEnd: dayjs(date.operationTimeEnd),
      };
    });
    const formData = new FormData();
    Object.entries(values).forEach(([key, value]) => {
      if (key === "workingHours") {
        formData.append(key, JSON.stringify(workingHours));
        return;
      }

      formData.set(key, value);
    });

    createStore(formData as any, {
      onSuccess: (data) => {
        dispatch(setStore(data));
        toast.success(t("store.success"));
        navigte("/products");
      },
      onError: (err) => {
        toast.error(t("store.faild"));
        toast.error(err?.message);

      },
    });
  };

  return (
    <AddStoreFormBody
      closeModal={closeModal}
      submitHandler={onSubmit}
      categoryOptions={categoryOptions}
    />
  );
};
