import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useStore, useUpdateStore } from "../../../@core/hooks";
import { StoreFormData } from "../../../@core/models";
import { EditStoreFormBody } from "./edit-store-form.hoc";
import { useQueryClient } from "react-query";
import { useEffect, useState } from "react";
import { updateStore } from "../../../@core/slice/merchant.slice";
import "../style.scss";
import { addTimeToDate } from "../../../utils/dayjs.util";

interface Props extends React.HTMLProps<HTMLDivElement> {
  closeModal: () => void;
}

export const EditStoreForm: React.FC<Props> = ({ closeModal }) => {
  const { data } = useStore();
  const { mutate: editStore } = useUpdateStore();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { t, i18n } = useTranslation();
  const [initialImageUrl, setInitialImageUrl] = useState<string | null>(null);
  const [initialNameAr, setInitialNameAr] = useState<string | null>(null);
  const [initialNameEn, setInitialNameEn] = useState<string | null>(null);
  const EngLang = i18n.language === "en";

  useEffect(() => {
    if (data?.data.image) {
      setInitialImageUrl(data.data.image);
    }
    setInitialNameEn(
      EngLang ? data?.data?.name || "" : data?.data?.translation?.name || ""
    );
    setInitialNameAr(
      EngLang ? data?.data?.translation?.name || "" : data?.data?.name || ""
    );
  }, [EngLang, data]);

  const onSubmit = (values: StoreFormData) => {
    const workingHours = values.workingHours.map((date: any) => {
      return {
        day: date.day,
        operationTimeStart: dayjs(
          date.operationTimeStart,
          "YYYY-MM-DDTHH:mm:ss.sssZ"
        ).format(),
        operationTimeEnd: dayjs(
          date.operationTimeEnd,
          "YYYY-MM-DDTHH:mm:ss.sssZ"
        ).format(),
      };
    });
    const formData = new FormData();
    formData.append("openStatus", "Open");

    Object.entries(values).forEach(([key, value]) => {
      if (key === "workingHours") {
        formData.append(key, JSON.stringify(workingHours));
        return;
      }

      if (key === "image" && value === initialImageUrl) {
        return;
      }
      if (key === "nameAr" && value === initialNameAr) {
        return;
      }
      if (key === "nameEn" && value === initialNameEn) {
        return;
      }
      formData.append(key, value);
    });
    editStore(
      {
        id: data?.data._id,
        body: formData,
      },
      {
        onSuccess: (data: any) => {
          dispatch(updateStore({ data: data.data }));
          toast.success(t("success.successfullyUpdated"));
          queryClient.invalidateQueries("store");
        },
        onError: (err) => {
          toast.error(t("errors.faildUpdated"));
          toast.error(err?.message);
        },
      }
    );
  };

  return <EditStoreFormBody closeModal={closeModal} submitHandler={onSubmit} />;
};
